import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/Layout.js";
import LinkCreate from "../../components/LinkCreate";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`👩‍🏫 Crear link de pago`}</h1>
    <p>{`Rellena el siguiente formulario para crear un nuevo link de pago.`}</p>
    <LinkCreate mdxType="LinkCreate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      